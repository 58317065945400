<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner" v-if="userInfo.usertype!=6&&userInfo.usertype!=5">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="公司/项目" prop="monitorpointname" style="width: 25%">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="地址" prop="address" style="width: 25%">
              <a-input v-model.trim="queryParams.address" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="项目名称" prop="monitorpointname" style="width: 25%">
              <a-input v-model.trim="queryParams.monitorpointname" placeholder="请输入"></a-input>
            </a-form-model-item>
            <div class="query-btns" style="width: 20%;float: right">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
            </div>
          </a-form-model>
        </div>
        <div v-show="showAdvanced" class="advanced-query">
          <a-form-model ref="advancedForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="项目ID" prop="monitorpointnum" style="width: 25%">
              <a-input v-model.trim="queryParams.monitorpointnum" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="区域" prop="areacode" style="width: 25%">
              <a-cascader :field-names="{ label: 'areaname', value: 'areacode', children: 'children' }" :options="areaOptions" v-model="queryAreaSelected" :load-data="areaOnLoadChildren" placeholder="请选择区域" :change-on-select="true"></a-cascader>
            </a-form-model-item>
            <a-form-model-item v-if="userInfo.usertype=='0'||userInfo.usertype=='1'" label="使用单位" prop="userdepname" style="width: 25%">
              <a-input v-model="queryParams.userdepname" placeholder="请输入"></a-input>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">{{pageTitle}}</h3>
          <div class="table-btns">
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="monitorpointid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="operation" slot-scope="value, record">
            <a-button type="link" @click="modifyProject(record)">修改</a-button>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <a-modal :visible.sync="modalVisible" title="修改项目所属片区" @cancel="modalVisible=false">
      <template slot="footer">
        <a-button type="primary" @click="confirm">确定</a-button>
        <a-button  @click="modalVisible=false">取消</a-button>
      </template>
      <a-form-model ref="queryForm" :model="modalDetailData" class="query-form":label-col="{span:8}" :wrapper-col="{span:12}">
        <a-form-model-item label="项目ID" prop="equipmentname">
          <a-input v-model.trim="modalDetailData.monitorpointnum" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item label="项目名称" prop="monitorpointname">
          <a-input v-model.trim="modalDetailData.monitorpointname" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item label="所属片区" prop="recordtime">
          <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="deptOptions.filter(item => item.deptype=='1')" v-model="parentIdSelected" :load-data="deptOnLoadChildren" placeholder="" :change-on-select="true" :allow-clear="false"></a-cascader>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </page-container>
</template>
<script>
import {getItemFromArrayByKey, getTimeRange} from 'U'
import { mapState } from 'vuex'
import moment from 'moment'
import areaselect from '@/mixins/areaselect'
import deptselect from '@/mixins/deptselect'
import pagination from '@/mixins/pagination'
import {getMonitorPointChangeList, modifyMonitorPointParentUserDep} from "A/monitoring";
export default {
  name: 'monitorPointChange',
  mixins: [areaselect, deptselect, pagination],
  components: {
  },
  props: ['pageType'],
  data() {
    return {
      moment,
      showAdvanced: false,
      queryParams: {
        monitorpointnum: '',
        address: '',
        areacode: '',
        monitornum:'',
        userdepname:'',
        devicenum:'',
        userdepid:'',
        userdepsid:'',
      },
      queryAreaSelected: [],
      queryInstallDate: null,
      userdepidCascaderSelected: [],
      tableColumns: [
        {
          title: '项目ID',
          dataIndex: 'monitorpointnum',
          key: 'monitorpointnum',
          ellipsis: true,
          width: 165
        },
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '地址',
          dataIndex: 'address',
          key: 'address',
          ellipsis: true,
        },
        {
          title: '所属片区',
          dataIndex: 'userdepname',
          key: 'userdepname',
          ellipsis: true,
        },
        {
          title: '操作',
          key: 'operation',
          align:'center',
          width: 70,
          scopedSlots: { customRender: 'operation' }
        },
      ],
      tableData: [],
      tableLoading: false,
      modalVisible: false,
      modalShowType: '',
      modalDetailData: {
        monitorpointnum: '',
        monitorpointname: '',
        parentid: '',
      },
      parentIdSelected:[],
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    pageTitle() {
      return '项目归属档案';
    },
    breadcrumb() {
      return [
        {
          name: '基础功能管理',
          path: ''
        },
        {
          name: this.pageTitle,
          path: ''
        },
      ]
    },
  },
  watch: {
    queryAreaSelected(val) {
      if(val && val.length) {
        this.queryParams.areacode = val[val.length-1];
      }else {
        this.queryParams.areacode = '';
      }
    },
    queryInstallDate(val) {
      let {start, end} = getTimeRange(val);
      this.queryParams.installstarttime = start;
      this.queryParams.installendtime = end;
    },
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepsid = val[val.length-1];
      }else {
        this.queryParams.userdepsid = '';
      }
    },
    parentIdSelected(val) {
      if(val && val.length) {
        this.modalDetailData.parentid = val[val.length-1];
      }else {
        this.modalDetailData.parentid = '';
      }
    },
  },
  created() {
    this.init();
    this.initDeptOptionsAll();
  },
  methods: {
    init() {
      this.getTableData();
    },
    resetQueryParams() {
      this.queryAreaSelected = [];
      this.queryInstallDate = [];
      this.userdepidCascaderSelected = [];
      this.$refs.queryForm.resetFields();
      this.$refs.advancedForm.resetFields();
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getMonitorPointChangeList(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    modifyProject(record){
      this.modalDetailData=record
      this.initDeptOptionsByUserdepid(record.userdepid, 'parentIdSelected', false)
      this.modalVisible=true
    },
    confirm(){
      modifyMonitorPointParentUserDep({
        userdepid:this.modalDetailData.userdepid,
        parentid:this.modalDetailData.parentid,
      }).then(res=>{
        if(res&&res.returncode=='0'){
          this.getTableData()
          this.$message.success("修改成功")
          this.modalVisible=false
        }
      })
    },
  }
}
</script>
<style lang="scss" scoped>
</style>